<template>
  <div>
    <b-tabs
      content-class="pt-1"
      fill
    >
      <b-tab
        title="PENDIENTES"
      >
        <b-row>
          <b-col
            v-for="(ticket, o) in filtrarData('ASIGNADO')"
            :key="ticket.id"
            cols="12"
          >
            <card-ticket
              :id="ticket.id"
              :key="ticket.id"
              :ticket="ticket"
              :contador="o+1"
              :operacion="'N'"
              @actualizar-pagina="reaload()"
            />
          </b-col>
        </b-row>
      </b-tab>
      <b-tab
        title="EN CURSO"
      >
        <b-col
          v-for="(ticket, o) in filtrarData('EN CURSO')"
          :key="ticket.id"
          cols="12"
        >
          <card-ticket
            :id="ticket.id"
            :key="ticket.id"
            :ticket="ticket"
            :contador="o+1"
            :operacion="'U'"
            @refrescar-pagina="reaload()"
          />
        </b-col>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {
  BTabs, BTab, BRow, BCol,
} from 'bootstrap-vue'
import { findTickets } from '@/utils/tickets'
import CardTicket from '@/components/Tickets/tecnico/CardTicket.vue'

export default {
  name: 'CardTickets',
  components: {
    CardTicket,
    BTabs,
    BTab,
    BRow,
    BCol,
  },
  data() {
    return {
      tickets: [],
      usuario: JSON.parse(localStorage.getItem('userData')),
    }
  },
  created() {
    this.reaload()
  },
  methods: {
    async reaload() {
      const filter = {
        where: {
          and: [
            { 'tecnicoEncargado.id': this.usuario.id },
            { estado: { neq: 'FINALIZADA' } },
            { estado: { neq: 'PENDIENTE' } },
          ],
        },
        order: ['fechaCreacion DESC'],
      }
      this.tickets = await findTickets(filter)
    },
    filtrarData(status) {
      return this.tickets.filter(ticket => ticket.estado === status)
    },
  },
}
</script>

<style scoped>

</style>
