<template>
  <div>
    <b-button
      :variant="getVariantTrabajo()"
      @click="abrirModal"
    >
      {{ getTituloTrabajo() }}
    </b-button>
    <b-modal
      v-model="verModal"
      hide-footer
      title="Seguimiento"
      no-close-on-backdrop
      centered
      @hide="limpiarCampos"
    >
      <b-overlay
        :show="loading"
      >
        <validation-observer ref="simpleRules">
          <b-form>
            <b-row>
              <!-- esta col funciona solo cuando se selecciona Agregar Imagen -->
              <b-col
                v-if="estado === 'INICIO'"
                cols="12"
              >
                <br>
                <h6>Foto del Inicio del Trabajo</h6>
              </b-col>
              <!-- esta col funciona solo cuando se selecciona Agregar Imagen -->
              <b-col
                v-if="estado === 'INICIO'"
                cols="12"
              >
                <center>
                  <imagen
                    :key="'inicio'"
                    :leyenda="'inicio'"
                    @cargar-file="imagenInicio"
                  />
                </center>
              </b-col>
              <!-- esta col funciona solo cuando se selecciona Finalizar Insepccion -->
              <b-col
                v-if="estado === 'FIN' && tipoTrabajo === 'CAMBIO_LAMPARA'"
                cols="12"
              >
                <h6 class="text-primary">
                  Cambio de Lámpara
                </h6>
              </b-col>
              <b-col v-if="estado === 'FIN' && tipoTrabajo === 'CAMBIO_LAMPARA'">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Potencia"
                    rules="required"
                  >
                    <h6>Potencia</h6>
                    <b-form-input
                      v-model="potencia"
                      type="number"
                      :state="errors.length > 0 ? false:null"
                      required
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col v-if="estado === 'FIN' && tipoTrabajo === 'CAMBIO_LAMPARA'">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Código anterior"
                    rules="required"
                  >
                    <h6>Código anterior</h6>
                    <b-form-input
                      v-model="codigoAnterior"
                      :state="errors.length > 0 ? false:null"
                      disabled
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col v-if="estado === 'FIN' && tipoTrabajo === 'CAMBIO_LAMPARA'">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Código nuevo"
                    rules="required"
                  >
                    <h6>Código nuevo</h6>
                    <b-form-input
                      v-model="codigoNuevo"
                      :state="errors.length > 0 ? false:null"
                      required
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-if="estado === 'FIN'"
                cols="12"
              >
                <h6>Foto de la Finalización</h6>
              </b-col>
              <!-- esta col funciona solo cuando se selecciona Finalizar Insepccion -->
              <b-col
                v-if="estado === 'FIN'"
                cols="12"
              >
                <center>
                  <imagen
                    :key="'fin'"
                    :leyenda="'fin'"
                    @cargar-file="imagenFin"
                  />
                </center>
              </b-col>
              <b-col cols="12">
                <h5>
                  Comentarios
                </h5>
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Comentarios"
                    rules="required"
                  >
                    <b-form-textarea
                      v-model="comentarios"
                      :state="errors.length > 0 ? false:null"
                      rows="2"
                      required
                      no-resize
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                class="align-content-center"
              >
                <center>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mt-2 mr-1 align-self-center"
                    @click="validationForm"
                  >
                    <feather-icon
                      icon="SaveIcon"
                      class="mr-50"
                    />
                    <span class="align-middle">Guardar Seguimiento</span>
                  </b-button>
                </center>
                <br>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-overlay>
    </b-modal>
  </div>
</template>
<script>
import {
  BModal, BCol,
  BRow, BButton,
  BFormTextarea, BOverlay, BFormGroup, BForm, BFormInput,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Imagen from '@/components/Inspecciones/Imagen.vue'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import { mensajeError, mensajeInformativo } from '@/utils/mensajes'
import { updateCreateTicket } from '@/utils/tickets'
import { getPoste, updateCreatePoste } from '@/utils/postes'
import { saveImagen } from '@/utils/imagenes'

export default {
  components: {
    Imagen,
    BCol,
    BRow,
    BForm,
    BModal,
    BButton,
    BOverlay,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    ticket: {
      type: Object,
      required: true,
    },
    item: {
      type: Object,
      required: false,
    },
    estado: {
      type: String,
      required: false,
    },
    tipoTrabajo: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      posteId: null,
      potencia: null,
      codigoNuevo: '',
      codigoAnterior: '',
      required,
      verModal: false,
      loading: false,
      comentarios: '',
      fotoInicio: '',
      fotoFin: '',
      localization: null,
      usuario: JSON.parse(localStorage.getItem('userData')),
    }
  },
  created() {
    if (this.estado === 'FIN' && this.tipoTrabajo === 'CAMBIO_LAMPARA') {
      this.potencia = this.ticket.punto.potencia
      this.codigoAnterior = this.ticket.punto.serieLampara
      this.posteId = this.ticket.punto.id
    }
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        // eslint-disable-next-line no-return-assign
        pos => (
          this.localization = {
            latitude: pos.coords.latitude,
            longitude: pos.coords.longitude,
          }),
        error => {
          console.error('Error Obteniendo Latitud y Longitud!', error)
          this.localization = null
        },
      )
    }
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.guardarTicket()
        }
      })
    },
    async guardarTicket() {
      try {
        this.loading = true
        if (this.estado === 'INICIO' && this.fotoInicio === '') {
          mensajeError('Debe ingresar una fotografía del inicio del trabajo correctivo')
          return
        }
        if (this.estado === 'FIN' && this.fotoFin === '') {
          mensajeError('Debe ingresar una fotografía de la finalización del trabajo correctivo')
          return
        }
        const usuario = { ...this.usuario }
        const encargado = {
          id: usuario.id,
          nombre: `${usuario.firstName} ${usuario.firstSurname}`,
          email: usuario.email,
        }
        const body = this.item
        const urlBucket = `tickets/${this.ticket.id}`
        const isMobile = window.screen.width <= 760
        if (!this.item.seguimiento) {
          this.item.seguimiento = []
        }
        if (this.estado === 'INICIO') {
          const imagenInicio = await saveImagen(urlBucket, this.localization, this.fotoInicio, 'Inicio del Trabajo', this.comentarios, encargado, isMobile)
          if (imagenInicio) {
            body.seguimiento.push(imagenInicio)
          }
          body.estado = 'FIN'
        }
        if (this.estado === 'FIN' && (this.tipoTrabajo === null || this.tipoTrabajo === '')) {
          const imagenFin = await saveImagen(urlBucket, this.localization, this.fotoFin, 'Fin del Trabajo', this.comentarios, encargado, isMobile)
          if (imagenFin) {
            body.seguimiento.push(imagenFin)
          }
        }
        if (this.tipoTrabajo === 'CAMBIO_LAMPARA' && this.estado === 'FIN') {
          const imagenCambioLampara = await saveImagen(urlBucket, this.localization, this.fotoFin, 'Fin del Trabajo Cambio de Lámpara', this.comentarios, encargado, isMobile)
          if (imagenCambioLampara) {
            imagenCambioLampara.cambioLampara = {
              potencia: this.potencia,
              codigoAnterior: this.codigoAnterior,
              codigoNuevo: this.codigoNuevo,
            }
            body.seguimiento.push(imagenCambioLampara)
          }
          const poste = await getPoste(this.posteId)

          if (poste) {
            poste.serieLampara = this.codigoNuevo
            poste.potencia = Number(this.potencia)

            await updateCreatePoste(poste, 1)
          }
        }
        await updateCreateTicket(this.ticket, 1)
        this.limpiarCampos()
        mensajeInformativo('Guardado', 'Seguimiento guardado correctamente!')
        this.$emit('actualizar')
        this.verModal = false
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    abrirModal() {
      this.verModal = true
      if (this.estado === 'FIN' && this.tipoTrabajo === 'CAMBIO_LAMPARA') {
        this.potencia = this.ticket.punto.potencia
        this.codigoAnterior = this.ticket.punto.serieLampara
        this.posteId = this.ticket.punto.id
      }
    },
    getTituloTrabajo() {
      if (this.estado === 'INICIO') {
        return 'Iniciar Trabajo Correctivo'
      }
      return 'Finalizar Trabajo Correctivo'
    },
    getVariantTrabajo() {
      if (this.estado === 'INICIO') {
        return 'relief-secondary'
      }
      return 'relief-danger'
    },
    limpiarCampos() {
      this.fotoInicio = ''
      this.fotoFin = ''
      this.comentarios = ''
    },
    imagenInicio(file) {
      this.fotoInicio = file
    },
    imagenFin(file) {
      this.fotoFin = file
    },
  },
}

</script>
