<template>
  <div>
    <b-card
      :header="`Poste No. ${punto.numeroPoste}`"
      :title="`Ticket No. ${ticket.ticket}`"
      :footer="`${getDays()}`"
      class="text-center"
      :footer-class="getClassFooter(ticket)"
    >
      <b-row>
        <b-col cols="8">
          <b-row>
            <b-col cols="4">
              <span
                class="float-left"
                style="font-size: x-small"
              >Poste No.</span>
            </b-col>
            <b-col cols="8">
              <b-form-textarea
                :value="punto.numeroPoste"
                plaintext
                style="font-size: x-small"
                no-resize
                class="float-left"
              />
            </b-col>
            <b-col cols="4">
              <span
                class="float-left"
                style="font-size: x-small"
              >Serie Lámpara.</span>
            </b-col>
            <b-col cols="8">
              <b-form-textarea
                :value="punto.serieLampara"
                plaintext
                style="font-size: x-small"
                no-resize
                class="float-left"
              />
            </b-col>
            <b-col cols="4">
              <span
                class="float-left"
                style="font-size: x-small"
              >Grupo </span>
            </b-col>
            <b-col cols="8">
              <b-form-textarea
                :value="punto.objGrupo ? punto.objGrupo.nombre : ''"
                plaintext
                style="font-size: x-small"
                no-resize
                class="float-left"
              />
            </b-col>
            <b-col cols="4">
              <span
                class="float-left"
                style="font-size: x-small"
              >Falla</span>
            </b-col>
            <b-col cols="8">
              <b-form-textarea
                :value="ticket.falla.nombre"
                plaintext
                style="font-size: x-small"
                no-resize
                class="float-left"
              />
            </b-col>
            <b-col cols="4">
              <span
                class="float-left"
                style="font-size: x-small"
              >Tipo</span>
            </b-col>
            <b-col cols="8">
              <b-form-textarea
                :value="ticket.falla.tipoFalla ? ticket.falla.tipoFalla : 'FALLA' "
                plaintext
                style="font-size: x-small"
                no-resize
                class="float-left"
              />
            </b-col>
            <b-col cols="4">
              <span
                class="float-left"
                style="font-size: x-small"
              >Municipio</span>
            </b-col>
            <b-col cols="8">
              <b-form-input
                :value="punto.obj_localidad ? punto.obj_localidad.municipio.nombre : ''"
                plaintext
                size="sm"
                class="float-left"
              />
            </b-col>
            <b-col cols="4">
              <span
                class="float-left"
                style="font-size: x-small"
              >Localidad</span>
            </b-col>
            <b-col cols="8">
              <b-form-textarea
                :value="punto.obj_localidad ? punto.obj_localidad.nombre : ''"
                plaintext
                style="font-size: x-small"
                no-resize
                class="float-left"
              />
            </b-col>
            <b-col cols="4">
              <span
                class="float-left"
                style="font-size: x-small"
              >Dirección</span>
            </b-col>
            <b-col cols="8">
              <b-form-textarea
                :value="`${punto.calleAvenida} ${punto.numeral} ${punto.obj_localidad ? 'ZONA ' + punto.obj_localidad.zona : ''}`"
                plaintext
                no-resize
                style="font-size: x-small"
                class="float-left"
              />
            </b-col>
            <b-col cols="4">
              <span
                class="float-left"
                style="font-size: x-small"
              >Comentarios</span>
            </b-col>
            <b-col cols="8">
              <b-form-textarea
                :value="ticket.comentarios"
                plaintext
                no-resize
                style="font-size: x-small"
                class="float-left"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="4">
          <create-ticket
            v-if="operacion ==='U'"
            origen="TECNICO"
            :punto="punto"
            :id-origen="ticket.id"
            titulo="Ingresar Trabajo Adicional"
            variante="relief-primary"
            @notificar-dialog="reaload()"
          />
        </b-col>
      </b-row>
      <br>
      <template>
        <b-table
          v-if="operacion !=='N' && ticket.trabajosCorrectivos && ticket.trabajosCorrectivos.length > 0"
          responsive="sm"
          :fields="fields"
          :items="ticket.trabajosCorrectivos"
        >
          <template #cell(item)="data">
            <span>{{ data.item.nombre }}</span>
          </template>
          <template #cell(seguimiento)="data">
            <modal-trabajos-correctivos
              v-if="!data.item.seguimiento || data.item.seguimiento.length < 2"
              :ticket="ticket"
              :item="data.item"
              :estado="data.item.estado"
              :tipo-trabajo="data.item.tipoTrabajo"
              @actualizar="reaload()"
            />
            <h5
              v-else
              style="color:#C12E35;"
            >
              Completado
            </h5>
          </template>
        </b-table>
      </template>
      <modal-ticket
        v-if="operacion ==='U'"
        :ticket="ticket"
        operacion="I"
        @refrescar-pagina="reaload()"
        @actualizar-pagina="reaload()"
      />
      <br v-if="showFinishButton()">
      <modal-ticket
        v-if="operacion ==='N' || showFinishButton()"
        :ticket="ticket"
        :operacion="showFinishButton()? 'F' : operacion"
        @refrescar-pagina="reaload()"
      />
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCol, BRow, BFormTextarea, BFormInput, BTable,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  calcularMinutosTranscurridos,
  formatDate,
} from '@/utils/fechas'
import ModalTicket from '@/components/Tickets/tecnico/ModalTicket.vue'
import CreateTicket from '@/components/Tickets/CreateTicket.vue'
import { getPoste } from '@/utils/postes'
import { getItem, getlistado } from '@/utils/catalogos'
import ModalTrabajosCorrectivos from '@/components/Tickets/tecnico/ModalTrabajosCorrectivos.vue'
import { updateCreateTicket } from '@/utils/tickets'

export default {
  name: 'CardTicket',
  components: {
    ModalTrabajosCorrectivos,
    BCard,
    ModalTicket,
    BRow,
    BCol,
    BFormTextarea,
    BFormInput,
    CreateTicket,
    BTable,
  },
  directives: {
    Ripple,
  },
  props: {
    ticket: {
      type: Object,
      required: true,
    },
    operacion: {
      type: String,
      required: true,
    },
    contador: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      punto: {},
      fechaCreacion: '',
      verModalTrabajos: false,
      comentarios: null,
      fields: ['item', 'seguimiento'],
      usuario: JSON.parse(localStorage.getItem('userData')),
    }
  },
  async created() {
    this.punto = await getPoste(this.ticket.idPuntoIluminacion)
  },
  methods: {
    reaload() {
      this.$emit('refrescar-pagina')
    },
    dateFechaProgramada() {
      return formatDate(this.ticket.ruta.fechaProgramada, '/')
    },
    getMinutes() {
      const row = this.ticket
      const creation = (row.imagenes && row.imagenes.length > 0) ? new Date(row.imagenes[0].fechaHora) : new Date(this.ticket.fechaCreacion)
      const inicios = row.trackingEstados ? row.trackingEstados.filter(e => e.estado === 'VERIFICADO') : []
      const inicio = inicios.length > 0 ? new Date(inicios[0].horaFecha) : creation
      return calcularMinutosTranscurridos(inicio)
    },
    getDays() {
      const minutos = this.getMinutes()
      if (minutos === 1) return `${minutos} minuto en progreso`
      if (minutos < 60) {
        return `${minutos} minutos en progreso`
      }
      const horas = Math.round((minutos / 60))
      if (horas === 1) return `${horas} hora en progreso`
      return `${horas} horas en progreso`
    },
    showFinishButton() {
      if (this.ticket.estado === 'EN CURSO') {
        if (this.ticket.trabajosCorrectivos) {
          // eslint-disable-next-line no-restricted-syntax
          for (const trabajo of this.ticket.trabajosCorrectivos) {
            if (!trabajo.seguimiento || trabajo.seguimiento.length < 2) {
              return false
            }
          }
          return true
        }
        return true
      }
      return false
    },
    getClassFooter(row) {
      if (row.imagenes && row.imagenes.length > 0) {
        const horas = Math.round(this.getMinutes() / 60)
        if (horas < 6) return 'text-success'
        if (horas >= 6 && horas < 10) return 'text-secondary'
        if (horas >= 10 && horas < 12) return 'text-warning'
        return 'text-danger'
      }
      return 'text-muted'
    },
  },
}
</script>

<style scoped>

</style>
