var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-button',{attrs:{"variant":_vm.getVariantTrabajo()},on:{"click":_vm.abrirModal}},[_vm._v(" "+_vm._s(_vm.getTituloTrabajo())+" ")]),_c('b-modal',{attrs:{"hide-footer":"","title":"Seguimiento","no-close-on-backdrop":"","centered":""},on:{"hide":_vm.limpiarCampos},model:{value:(_vm.verModal),callback:function ($$v) {_vm.verModal=$$v},expression:"verModal"}},[_c('b-overlay',{attrs:{"show":_vm.loading}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[(_vm.estado === 'INICIO')?_c('b-col',{attrs:{"cols":"12"}},[_c('br'),_c('h6',[_vm._v("Foto del Inicio del Trabajo")])]):_vm._e(),(_vm.estado === 'INICIO')?_c('b-col',{attrs:{"cols":"12"}},[_c('center',[_c('imagen',{key:'inicio',attrs:{"leyenda":'inicio'},on:{"cargar-file":_vm.imagenInicio}})],1)],1):_vm._e(),(_vm.estado === 'FIN' && _vm.tipoTrabajo === 'CAMBIO_LAMPARA')?_c('b-col',{attrs:{"cols":"12"}},[_c('h6',{staticClass:"text-primary"},[_vm._v(" Cambio de Lámpara ")])]):_vm._e(),(_vm.estado === 'FIN' && _vm.tipoTrabajo === 'CAMBIO_LAMPARA')?_c('b-col',[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Potencia","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('h6',[_vm._v("Potencia")]),_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false:null,"required":""},model:{value:(_vm.potencia),callback:function ($$v) {_vm.potencia=$$v},expression:"potencia"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3513802989)})],1)],1):_vm._e(),(_vm.estado === 'FIN' && _vm.tipoTrabajo === 'CAMBIO_LAMPARA')?_c('b-col',[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Código anterior","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('h6',[_vm._v("Código anterior")]),_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"disabled":""},model:{value:(_vm.codigoAnterior),callback:function ($$v) {_vm.codigoAnterior=$$v},expression:"codigoAnterior"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,587766821)})],1)],1):_vm._e(),(_vm.estado === 'FIN' && _vm.tipoTrabajo === 'CAMBIO_LAMPARA')?_c('b-col',[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Código nuevo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('h6',[_vm._v("Código nuevo")]),_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"required":""},model:{value:(_vm.codigoNuevo),callback:function ($$v) {_vm.codigoNuevo=$$v},expression:"codigoNuevo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1383621628)})],1)],1):_vm._e(),(_vm.estado === 'FIN')?_c('b-col',{attrs:{"cols":"12"}},[_c('h6',[_vm._v("Foto de la Finalización")])]):_vm._e(),(_vm.estado === 'FIN')?_c('b-col',{attrs:{"cols":"12"}},[_c('center',[_c('imagen',{key:'fin',attrs:{"leyenda":'fin'},on:{"cargar-file":_vm.imagenFin}})],1)],1):_vm._e(),_c('b-col',{attrs:{"cols":"12"}},[_c('h5',[_vm._v(" Comentarios ")]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Comentarios","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"state":errors.length > 0 ? false:null,"rows":"2","required":"","no-resize":""},model:{value:(_vm.comentarios),callback:function ($$v) {_vm.comentarios=$$v},expression:"comentarios"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"align-content-center",attrs:{"cols":"12"}},[_c('center',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2 mr-1 align-self-center",attrs:{"variant":"primary"},on:{"click":_vm.validationForm}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"SaveIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Guardar Seguimiento")])],1)],1),_c('br')],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }